import { ValidationData } from './inputValidationTypes';
import { addErrorToValidationDataAndKeepMessage } from './validityUtils';

export const validatePhoneFormat = (
  props: {
    phoneFormat?: string;
    value: string;
  },
  validationData: ValidationData,
): ValidationData => {
  const { value, phoneFormat } = props;
  const phoneFormatWithoutSpacesLength = phoneFormat
    ? phoneFormat.replace(/-/g, '').length
    : 0;

  if (
    !phoneFormat ||
    (phoneFormat && value.length === phoneFormatWithoutSpacesLength)
  ) {
    return validationData;
  }

  let validationMessage: 'tooShort' | 'tooLong';

  if (value.length < phoneFormatWithoutSpacesLength) {
    validationMessage = 'tooShort';
  } else {
    validationMessage = 'tooLong';
  }

  return addErrorToValidationDataAndKeepMessage(
    validationData,
    validationMessage,
  );
};
