import { LinkProps } from '@wix/editor-elements-types';

const regex = /(?:<a.*?href=["'])(.*?)(?:["'])/gi;

export const convertLinkProperties = (
  html: string,
  getLinkProps: (href: string) => LinkProps,
  resolveHref: (properties: LinkProps) => string = props => props.href || '',
) => {
  const replaces = [];
  let match;

  do {
    match = regex.exec(html);
    if (match) {
      const [fullMatch, groupMatch] = match;
      replaces.push([
        fullMatch,
        fullMatch.replace(groupMatch, resolveHref(getLinkProps(groupMatch))),
      ]);
    }
  } while (match);

  return replaces.reduce(
    (a, [matcho, replace]) => a.replace(matcho, replace),
    html,
  );
};
