import { VideoLegacyProps } from '@wix/thunderbolt-components-native';
import { reportError } from '@wix/editor-elements-corvid-utils';
import { IVideoData, IVideoMethods } from '../../VideoLegacy.types';
import * as videoUtils from './playersVideoUtils';

const PLAYERS = ['YOUTUBE', 'VIMEO', 'DAILYMOTION', 'FACEBOOK'];

const getMethodForType = (methodName: IVideoMethods) => videoUtils[methodName];

export const getVideoUrl = ({ videoType, videoId }: IVideoData) => {
  if (videoType && videoId) {
    const videoUrlGetter = getMethodForType('getVideoUrl');
    return (videoUrlGetter && videoUrlGetter(videoId, videoType)) || '';
  }

  return '';
};

export const getVideoDataFromUrl = (videoUrl: string) => {
  let videoData = {};

  PLAYERS.every(videoType => {
    const videoIdGetter = getMethodForType('getVideoIdFromUrl');
    const videoId =
      videoIdGetter &&
      videoIdGetter(videoUrl, videoType as VideoLegacyProps['videoType']);

    if (videoId) {
      videoData = {
        videoType,
        videoId,
      };

      return false;
    }
    return true;
  });

  return videoData as IVideoData;
};

export const isValidUrl = (urlToTest: string) => {
  const regexp = /^(?:(?:https?:)\/\/)(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?$/i;

  if (regexp.test(urlToTest)) {
    return true;
  } else {
    reportError(
      `The videoUrl parameter that is passed to the videoUrl method cannot be set to the value . It must be a valid URL`,
    );
    return false;
  }
};
