import { CorvidSDKApi } from '@wix/editor-elements-types';
import {
  assert,
  messages,
  reportError,
} from '@wix/editor-elements-corvid-utils';
import { ISlideShowSlideSDK } from '../../SlideShowSlide/SlideShowSlide.types';
import { IStateBoxProps, IStateBoxSDK } from '../StateBox.types';

export const isValidStateReference = (
  functionArgs: [string | ISlideShowSlideSDK],
  sdkFactoryArgs: CorvidSDKApi<IStateBoxProps, IStateBoxSDK>,
) => {
  const [stateReference] = functionArgs;
  const isObjectStateReference = assert.isObject(stateReference);

  const states = sdkFactoryArgs.getChildren();
  let inputState = -1;
  // if stateRefernce is object - validate object by properties and search by id
  if (assert.isObject(stateReference)) {
    const isValidStateSDKObject = Object.keys(states[0]).every(key =>
      stateReference.hasOwnProperty(key),
    );
    if (!isValidStateSDKObject) {
      reportError(
        messages.invalidTypeMessage({
          propertyName: 'stateReference',
          functionName: 'changeState',
          value: stateReference,
          types: ['state', 'string'],
          index: undefined,
        }),
      );
      return false;
    }
    inputState = states.findIndex(state => state.id === stateReference.id);
  }
  // if stateReference is string - find by id
  if (assert.isString(stateReference)) {
    inputState = states.findIndex(state => state.id === stateReference);
  }
  if (inputState < 0) {
    reportError(
      messages.invalidStateInputMessage({
        value: isObjectStateReference
          ? (stateReference as ISlideShowSlideSDK).id
          : (stateReference as string),
        propertyName: 'stateReference',
        functionName: 'changeState',
        stateBoxId: sdkFactoryArgs.metaData.role,
      }),
    );
    return false;
  }
  return true;
};
