import { LinkProps } from '@wix/thunderbolt-becky-types';
import { createMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import { TPAImage } from '../TPAGallery.types';

export function convertImagesToUserModel(
  images: Array<TPAImage>,
  linkUtils: any,
) {
  return images.map((image: TPAImage) => {
    const {
      type,
      title,
      width,
      height,
      uri,
      alt,
      description,
      link,
      src,
      internalLink,
    } = image;

    const mediaSrc =
      uri || src
        ? createMediaSrc({
            mediaId: uri || src,
            type: type.toLowerCase(),
            title,
            width,
            height,
          })
        : { item: '' };

    let resolvedLink;
    if (link) {
      try {
        if (link.type === 'AnchorLink') {
          const isScrollTopOrBottom = [
            'SCROLL_TO_TOP',
            'SCROLL_TO_BOTTOM',
          ].includes(link.anchorDataId);

          if (isScrollTopOrBottom) {
            const transformTopOrBottom =
              link.anchorDataId === 'SCROLL_TO_TOP' ? '#top' : '#bottom';
            resolvedLink = transformTopOrBottom;
          } else {
            const anchorDataId = link.anchorDataId.name
              ? `#${link.anchorDataId.name.toLowerCase().replace(' ', '')}`
              : `#${link.anchorDataId}`;

            const linkData = {
              type: 'AnchorLink',
              pageId: link.pageId.id,
              anchorDataId,
            };
            resolvedLink = linkUtils.getLinkUrlFromDataItem(linkData);
          }
        } else {
          resolvedLink = linkUtils.getLinkUrlFromDataItem(link);
        }
      } catch (e) {
        resolvedLink = link.href;
        if (internalLink) {
          const internalLinkWithHome =
            internalLink === '/' ? '/home' : internalLink;
          resolvedLink = internalLinkWithHome;
        }
      }
    }

    return {
      type: type.charAt(0).toUpperCase() + type.slice(1).toLowerCase(),
      ...(description && { description }),
      alt: alt || '',
      ...(title && { title }),
      ...(height && { height }),
      ...(width && { width }),
      ...(link && {
        link: resolvedLink,
      }),
      ...(link && link.target && { target: link.target }),
      src: mediaSrc.item || mediaSrc.error || '',
    };
  });
}

export function getFullUrlPropsFromDynamicUrl(
  resolvedLink: LinkProps | null,
  url: URL,
) {
  const paths = url.pathname.split('/');
  paths.pop();
  let pathStringBuilder = paths.filter(path => !!path).join('/');
  pathStringBuilder = pathStringBuilder ? `/${pathStringBuilder}` : '';

  const origin = url.origin;
  const baseUrl = `${origin}${pathStringBuilder}`;
  const trimmedPageRoute =
    resolvedLink && resolvedLink.href ? resolvedLink.href.substring(1) : '';

  return {
    trimmedPageRoute,
    fullUrl: `${baseUrl}${trimmedPageRoute}`,
  };
}
