import { VideoLegacyProps } from '@wix/thunderbolt-components-native';

const BASE_URLS = {
  YOUTUBE: 'https://www.youtube.com/watch?v=',
  VIMEO: 'https://vimeo.com/',
  DAILYMOTION: 'https://dailymotion.com/video/',
  FACEBOOK: 'https://www.facebook.com/',
};

const REGEX = {
  YOUTUBE: {
    longURL: /(?:youtube\.com\/watch[^\s]*[?&]v=)([\w-]+)/g,
    shortURL: /(?:youtu\.be\/)([\w-]+)/g,
  },
  VIMEO: /vimeo\.com\/(\d+)$/gi,
  DAILYMOTION: /dailymotion\.com\/(?:video|playlist)\/([^_/]+)/gi,
  FACEBOOK: /facebook\.com\/(.+\/videos\/[A-Za-z0-9._%-]*)/gi,
};

export const getVideoUrl = (
  videoId: string,
  videoType: VideoLegacyProps['videoType'],
) => {
  return BASE_URLS[videoType] + videoId;
};

export const getVideoIdFromUrl = (
  url: string,
  videoType: VideoLegacyProps['videoType'],
) => {
  const urlRegex = REGEX[videoType];

  const match =
    videoType === 'YOUTUBE'
      ? (urlRegex as { longURL: RegExp; shortURL: RegExp }).longURL.exec(url) ||
        (urlRegex as { longURL: RegExp; shortURL: RegExp }).shortURL.exec(url)
      : (urlRegex as RegExp).exec(url);

  if (match && match.length && match[1]) {
    // if there is a match, the second group is the id we want
    return match[1];
  }
  return '';
};
