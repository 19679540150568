import {
  withValidation,
  composeSDKFactories,
  createElementPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  assert,
  childrenPropsSDKFactory,
  toJSONBase,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  IStateBoxSDKFactory,
  IStateBoxProps,
  IStateBoxSDK,
  StateBoxSDKState,
} from '../StateBox.types';
import { ISlideShowSlideSDK } from '../../SlideShowSlide/SlideShowSlide.types';
import { isValidStateReference } from './customValidations';

const stateBoxSDKFactory: IStateBoxSDKFactory = ({
  props,
  registerEvent,
  getChildren,
  compRef,
  metaData,
  createSdkState,
}) => {
  const [state, setState] = createSdkState<StateBoxSDKState>({
    stateChangedHandlers: [],
  });

  registerEvent('onCurrentSlideChanged', (newSlideIndex: number) => {
    const states = getChildren();
    state.stateChangedHandlers.forEach(cb => cb(states[newSlideIndex]));
    setState({ stateChangedHandlers: [] });
  });

  const sdk = {
    get currentState() {
      const states = getChildren();
      return states[props.currentSlideIndex || 0];
    },
    get states() {
      return getChildren();
    },
    changeState(stateReference: string | ISlideShowSlideSDK) {
      const states = getChildren();
      const currentStateIndex = props.currentSlideIndex;
      const nextStateIndex = assert.isString(stateReference)
        ? states.findIndex(_state => _state.id === stateReference)
        : states.findIndex(_state => _state.id === stateReference.id);
      if (currentStateIndex === nextStateIndex) {
        return Promise.resolve(states[nextStateIndex]);
      }
      return new Promise<ISlideShowSlideSDK>(resolve => {
        setState({
          stateChangedHandlers: [
            ...state.stateChangedHandlers,
            () => resolve(states[nextStateIndex]),
          ],
        });
        compRef.moveToSlide(nextStateIndex);
      });
    },
    get type() {
      return '$w.MultiStateBox';
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type: sdk.type,
        currentStateId: sdk.currentState.id,
      };
    },
  };
  return sdk;
};

const customRules = {
  changeState: [isValidStateReference],
};

const stateBox = withValidation(
  stateBoxSDKFactory,
  {
    type: ['object'],
    properties: {
      changeState: {
        type: ['function'],
        args: [{ type: ['object', 'string'] }],
      },
    },
  },
  customRules,
);

export const sdk = composeSDKFactories<IStateBoxProps, IStateBoxSDK>(
  createElementPropsSDKFactory(),
  changePropsSDKFactory,
  clickPropsSDKFactory,
  childrenPropsSDKFactory,
  stateBox,
);

export default createComponentSDKModel(sdk);
