import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { reportError, withValidation } from '@wix/editor-elements-corvid-utils';
import { FillLayersProps } from '@wix/thunderbolt-components';
import { createMediaSrc } from '../media/mediaSrcHandler';
import * as mediaItemUtils from '../media/mediaItemUtils';
import {
  getScrollEffect,
  getFullMediaData,
  getMediaDataFromSrc,
  BG_VIDEO_DEFAULTS,
} from '../media/backgroundUtils';

export interface IBackgroundPropsSDKProps {
  fillLayers?: FillLayersProps;
}

export interface IBackgroundPropsSDK {
  background: { src: string };
}

const _backgroundPropsSDKFactory: CorvidSDKPropsFactory<
  IBackgroundPropsSDKProps,
  IBackgroundPropsSDK
> = ({ setProps, props, metaData }) => {
  return {
    get background() {
      return {
        get src() {
          const { fillLayers } = props;

          if (fillLayers?.video?.videoInfo?.videoId) {
            const { videoInfo } = fillLayers.video;
            const mediaItemUri = createMediaSrc({
              mediaId: videoInfo.videoId,
              type: mediaItemUtils.types.VIDEO,
              title: fillLayers.video.posterImageInfo.title,
              width: videoInfo.videoWidth,
              height: videoInfo.videoHeight,
              posterId: fillLayers.video.posterImageInfo.uri,
            });
            if (mediaItemUri.error) {
              return '';
            }

            return mediaItemUri.item || '';
          }

          if (fillLayers && fillLayers.image) {
            const mediaItemUri = createMediaSrc({
              mediaId: fillLayers.image.uri,
              type: mediaItemUtils.types.IMAGE,
              width: fillLayers.image.width,
              height: fillLayers.image.height,
              title: fillLayers.image.title,
            });
            if (mediaItemUri.error) {
              return '';
            }
            return mediaItemUri.item || '';
          }

          return '';
        },

        set src(newSrc: string) {
          const { fillLayers = {} } = props;
          if (!newSrc) {
            // clear the background fillLayers
            setProps({
              fillLayers: {
                containerId: metaData.compId,
              },
            });
            return;
          }

          const mediaData = getMediaDataFromSrc(newSrc);
          if (!mediaData) {
            reportError(
              `The "src" property cannot be set to "${newSrc}". It must be a valid URL starting with "http://", "https://", or "wix:image://, or a valid video URL starting with "wix:video://".`,
            );
            return;
          }

          const { hasBgScrollEffect, bgEffectName } = getScrollEffect(
            fillLayers,
          );

          if (mediaData.type === 'WixVideo') {
            getFullMediaData(mediaData, fullMediaRefData => {
              if (!fullMediaRefData) {
                return;
              }
              const propsFull = {
                fillLayers: {
                  containerId: metaData.compId,
                  backgroundMedia: hasBgScrollEffect
                    ? {
                        containerId: metaData.compId,
                        ...fillLayers.backgroundMedia,
                      }
                    : undefined,
                  hasBgFullscreenScrollEffect:
                    fillLayers.hasBgFullscreenScrollEffect,
                  video: {
                    ...BG_VIDEO_DEFAULTS,
                    alt: '',
                    posterImageInfo: {
                      containerId: metaData.compId,
                      hasBgScrollEffect,
                      bgEffectName,
                      ...mediaData.posterImageRef,
                    },
                    videoInfo: {
                      containerId: metaData.compId,
                      videoId: fullMediaRefData.mediaObject.videoId,
                      videoWidth: fullMediaRefData.mediaObject.videoWidth,
                      videoHeight: fullMediaRefData.mediaObject.videoHeight,
                      qualities: fullMediaRefData.mediaObject.qualities.filter(
                        (item: Record<string, any>) =>
                          item.quality !== 'storyboard',
                      ),
                      isVideoDataExists: '1',
                      videoFormat: fullMediaRefData.mediaObject.videoFormat,
                      playbackRate: fullMediaRefData.mediaObject.playbackRate,
                      autoPlay: fullMediaRefData.mediaObject.autoPlay,
                      hasBgScrollEffect,
                      bgEffectName,
                    },
                  },
                },
              };
              setProps(propsFull);
            });
            // change to poster (video partial props)
            setProps({
              fillLayers: {
                containerId: metaData.compId,
                hasBgFullscreenScrollEffect:
                  fillLayers.hasBgFullscreenScrollEffect,
                backgroundMedia: hasBgScrollEffect
                  ? {
                      containerId: metaData.compId,
                      ...fillLayers.backgroundMedia,
                    }
                  : undefined,
                video: {
                  ...BG_VIDEO_DEFAULTS,
                  alt: '',
                  posterImageInfo: {
                    containerId: metaData.compId,
                    hasBgScrollEffect,
                    bgEffectName,
                    ...mediaData.posterImageRef,
                  },
                  videoInfo: {
                    containerId: metaData.compId,
                    videoId: mediaData.videoId,
                    isVideoDataExists: false,
                  },
                },
              },
            });
          } else {
            // change to Image
            setProps({
              fillLayers: {
                containerId: metaData.compId,
                hasBgFullscreenScrollEffect:
                  fillLayers.hasBgFullscreenScrollEffect,
                backgroundMedia: hasBgScrollEffect
                  ? {
                      containerId: metaData.compId,
                      ...fillLayers.backgroundMedia,
                    }
                  : undefined,
                image: {
                  ...mediaData,
                  uri: mediaData.mediaId || '',
                  displayMode: 'fill',
                  containerId: metaData.compId,
                  name: '',
                  width: mediaData.width || 0,
                  height: mediaData.height || 0,
                  alt: '',
                  hasBgScrollEffect,
                  bgEffectName,
                },
              },
            });
          }
        },
      };
    },
  };
};

export const backgroundPropsSDKFactory = withValidation(
  _backgroundPropsSDKFactory,
  {
    type: ['object'],
    properties: {
      background: {
        type: ['object'],
        properties: {
          src: {
            type: ['string', 'nil'],
            warnIfNil: true,
          },
        },
      },
    },
  },
);
